<template>
    <v-app>
        <div>
            <Header/>
        </div>
        <main>
            <div class="filters">  
                <v-card :elevation="1" tile>
                    <v-card-title style="font-weight:bold;margin-left:10px">FILTER</v-card-title>
                    <v-col
                            v-for="n in current_filters.length"
                            :key="n"
                            class="d-flex child-flex"
                        >
                        <label class="cont">{{current_filters[n-1]}}
                            <input type="checkbox" @click="saveFilter(current_filters[n-1])">
                            <span class="checkmark"></span>
                        </label>
                    </v-col>
                    <input type="submit" value="Filtriraj" @click="filter()">
                </v-card>
            </div>
            <div class="product_container">

                <div class="path">
                    <router-link :to="{ name: 'FrontPage' }" style="color: rgb(106, 113, 116); padding-right:5px">
                        Početna
                    </router-link>
                    >
                    <router-link :to="{ name: 'ProductList', params: { categoryId: currentId } }" style="color: rgb(106, 113, 116); padding-left:5px">
                        {{this.currentCategory.naziv}}
                    </router-link>
                </div>
                <h1>{{this.currentCategory.naziv}}</h1>

                <div v-if="$vuetify.breakpoint.width < 700">
                    <div v-for="j in current_names.length" :key="j">
                        <v-row v-if="j < current_pictures.length/2 + 1">
                            <v-col
                                v-for="n in 2"
                                :key="n"   
                            >
                            <v-card v-if="2*j-n < current_pictures.length" tile class="product-card">
                                <router-link :to="{ name: 'Article', params: { categoryId: currentId, articleId: article_id[2*j-n] } }">
                                    <div>
                                        <v-img
                                        :src="current_pictures[2*j-n]"
                                        contain
                                        aspect-ratio="1.5"
                                        class="img-fluid"
                                        >
                                        </v-img>
                                        <div class="product-info">
                                            <div class="product_brand">{{current_brands[2*j-n]}}</div>
                                            <div class="product_name">{{current_names[2*j-n]}}</div>
                                        </div>
                                    </div>
                                </router-link>
                                <div class="button">
                                    <v-btn elevation="2" tile text style="font-weight:bold;font-size:10px;padding:10px" ><a class="link" href="mailto:pecnik@pecnik.hr" target="_blank">POŠALJI UPIT</a></v-btn>
                                </div>
                            </v-card>
                            </v-col>
                        </v-row>
                    </div>
                </div>
                <div v-if="$vuetify.breakpoint.width >= 700">
                    <v-row>
                        <v-col
                            v-for="n in current_pictures.length"
                            :key="n"
                            class="d-flex child-flex"
                            cols="12"
                            sm="4"
                        >
                        <v-card tile class="product-card">
                            <router-link :to="{ name: 'Article', params: { categoryId: currentId, articleId: article_id[n-1] } }">
                                <div>
                                    <v-img
                                    :src="current_pictures[n-1]"
                                    contain
                                    aspect-ratio="1.5"
                                    class="img-fluid"
                                    >
                                    </v-img>
                                    <div class="product-info">
                                        <div class="product_brand">{{current_brands[n-1]}}</div>
                                        <div class="product_name">{{current_names[n-1]}}</div>
                                    </div>
                                </div>
                            </router-link>
                            <div class="button">
                                <v-btn elevation="2" tile text style="font-weight:bold;font-size:14px;padding:20px" ><a class="link" href="mailto:pecnik@pecnik.hr" target="_blank">POŠALJI UPIT</a></v-btn>
                            </div>
                        </v-card>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </main>
        <div>
            <Footer/>
        </div>
    </v-app>
</template>

<script>
    import Header from '@/views/Header';
    import Footer from '@/views/Footer';
    
    export default {
        name: 'App',
        components: {
            Header,
            Footer
        },

        data: () => {
            return {
                checkbox: false,
                brands: [],
                current_filters: [],
                names: [],
                article_brands: [],
                pictures: [],
                checked_filters: [],
                current_brands: [],
                current_pictures: [],
                current_names: [],
                article_id: [],
                currentId: '',
                currentCategory: ''
            }
        },
        created () {
            this.currentCategory = this.$store.getters.currentCategory(this.$route.params.categoryId)
            this.currentId = this.currentCategory.ctg_id
        },
        mounted() {
            this.getArticles()
        },
        methods: {
            getArticles() {
                let i = 0
                var entry = this.$store.state.articles
                for (var index in entry) {
                    if (entry[index].kategorija == this.currentCategory.naziv) {
                        this.names[i] = entry[index].naziv
                        this.article_brands[i] = entry[index].brand
                        this.pictures[i] = entry[index].picture
                        this.article_id[i] = entry[index].id
                        i++
                    }
                }
                this.current_filters = this.article_brands.reduce(function(a,b){if(a.indexOf(b)<0)a.push(b);return a;},[]);
                this.current_names = this.names
                this.current_brands = this.article_brands
                this.current_pictures = this.pictures
            },
            getBrands() {
                this.brands = this.$store.state.brands
                var i = 0;
                for (brand in this.brands) {
                    for (article_brand in this.article_brands) {
                        if (brand == article_brand) {
                            this.current_filters[i++] = brand;
                            break;
                        }
                    }
                }
            },
            saveFilter(filter) {
                if (this.checked_filters.includes(filter)) {
                    this.checked_filters.splice(this.checked_filters.indexOf(filter), 1)
                }
                else {
                    this.checked_filters.push(filter)
                }
            },
            filter() {
                var brand_result = []
                var picture_result = []
                var name_result = []
                var k = 0
                for (var i in this.checked_filters) {
                    for (var j in this.article_brands) {
                        if (this.checked_filters[i] == this.article_brands[j]) {
                            brand_result[k] = this.article_brands[j]
                            picture_result[k] = this.pictures[j]
                            name_result[k] = this.names[j]
                            ++k
                        }
                    }
                }
                if (this.checked_filters.length == 0) {
                    this.current_names = this.names
                    this.current_brands = this.article_brands
                    this.current_pictures = this.pictures
                }
                else {
                    this.current_names = name_result
                    this.current_brands = brand_result
                    this.current_pictures = picture_result
                }
            }
        }
    }
</script>

<style>
main {
    padding-top: 0.5rem;
}
.filters {
    margin-top: 4rem;
    width: 16%;  
    float: left; 
}
.product_container {
    width: 84%;
    float: left;
    padding-left: 5rem;
    padding-right: 5rem;
}
#block {
    overflow: hidden;     
    display: block; 
}
.product-info {
    padding-top: 10px;
    padding-left: 10px;
    margin-bottom: 13%;
    text-align: left;
    color: black;
}
.product_brand {
    font-size:18px;
    font-weight:bold;
    padding-left:10px
}
.product_name {
    font-size:23px;
    font-family:Noto Sans, sans-serif;
    padding-left:10px;
    padding-right:10px
}
h1 {
    text-align: left;
    padding-top: 2rem;
    padding-bottom: 1.5rem;
}
.path {
    text-align: left;
    color: rgb(106, 113, 116);
}
.product-card{
    min-height: 408px;
    width: 100%;
    position: relative;
}
.button {
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    justify-content: center;
    margin-bottom: 12px;
}
.img-fluid {
    margin-top: 10px;
}
 /* Customize the label (the container) */
.cont {
    text-transform: uppercase;
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    margin-left:10px;
    cursor: pointer;
    font-size: 15px;
    text-align: left;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.cont input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.cont:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.cont input:checked ~ .checkmark {
  background-color: rgb(106, 113, 116);;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.cont input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.cont .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
} 

input[type=submit] {
  background-color: #f2f2f2;
  color: rgb(89, 92, 94);
  padding: 10px 20px;
  border: 1px solid rgb(206, 201, 201);
  margin-bottom: 10px;
  justify-content: left;
  /* display: block; */
  /* position: absolute; */
  /* margin-left: 15px; */
}
input[type=submit]:hover {
  background-color: #f2f2f2b6;
}
input:focus{
    outline: none;
}

@media screen and (max-width: 1000px) {
    main {
        padding-top: 0px;
    }
    .filters {
        top: 0;
        margin-top: 0px;
        width: 100%;  
    }
    .product_container {
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .path {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 600px) {
    main {
        padding-top: 0px;
    }
    .filters {
        top: 0;
        margin-top: 0px;
        width: 100%;  
    }
    .product_container {
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .path {
        margin-top: 2rem;
    }
    .product-card{
        min-height: 290px;
        width: 100%;
        position: relative;
    }
    .product_brand {
        padding-left: 5px;
        font-size:13px; 
    }
    .product_name {
        padding-left: 5px;
        padding-right: 5px;
        font-size:16px;   
    }
    .button {
        text-align: center;
        position: absolute;
        bottom: 0;
        width: 100%;
        justify-content: center;
        margin-bottom: 10px;
    }
    .cont {
        margin-bottom: 1px;
    }
    .product-info {
        margin-bottom: 1%;
    }
}
</style>
